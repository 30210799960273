import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import askmi from '../../images/askmi.png';

const AskmiAd = () => (
	<div style={{ marginTop: '4rem', maxWidth: '100%', textAlign: 'center' }}>
		<div id="askmiBigAd" style={{ transition: 'transform 0.25s ease' }}>
			<OutboundLink href="https://askmi.app" rel="noreferrer" target="_blank">
				<img
					alt="Askmi Logo"
					src={askmi}
					style={{
						border: '1.5rem solid #724CF9',
						borderRadius: '1.5rem',
						width: '17rem',
					}}
				/>
			</OutboundLink>
		</div>
		<div style={{ marginTop: '2rem' }}>
			<i>Exclusive invitation, free for Google Feud players:</i>
			<div style={{ fontSize: '1.5rem', marginTop: '1rem' }}>
				{'Join '}
				<OutboundLink
					href="https://askmi.app"
					rel="noreferrer"
					style={{ fontWeight: 'bold' }}
					target="_blank">
					Askmi
				</OutboundLink>
				{'. Connect through curiousity.'}
			</div>
		</div>
	</div>
);
export default AskmiAd;
