import * as React from 'react';
import { Helmet } from 'react-helmet';
import AskmiAd from '../components/Ads/AskmiAd';
import VideoAd from '../components/Ads/VideoAd';
import { Game } from '../components/Game';
import Logo from '../components/Logo';

const IndexPage = () => {
	return (
		<>
			<Helmet>
				<title>Google Feud | How does Google autocomplete this query?</title>
			</Helmet>
			<Logo />
			<Game />
			<AskmiAd />
			<VideoAd />
		</>
	);
};
export default IndexPage;
